import React from "react";
import {graphql} from "gatsby";
import Container from "../components/container";
import SEO from "../components/seo";
import Img from "gatsby-image";
import Content from "../components/content";
import Wrapper from "../components/wrapper";
import PortableText from "../components/portableText";
import Email from '../components/email'
import Hero from "../components/hero";
import HeroLayout from "../components/heroLayout";
import JobCard from "../components/card-job";

export const query = graphql`
    query KarrierePageQuery {
        career: sanityCareerPage {
            title
            text
            image {
                ...MainImage
            }
            mobileImage: image {
                ...MobileMainImage
            }
            _rawAboveAds(resolveReferences: {maxDepth: 5})
            _rawBelowAds(resolveReferences: {maxDepth: 5})
        }
        jobs: allSanityJob(
            sort: { fields: [publishedAt], order: DESC }
            filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
        ) {
            edges {
                node {
                    title
                    deadlineAt
                    slug {
                        current 
                    }
                    mainImage {
                        ...MainImage
                    }
                    mobileImage: mainImage {
                        ...MobileMainImage
                    }
                    _rawExcerpt(resolveReferences: {maxDepth: 5})
                }
            }
        }
    }
`;

class KarrierePage extends React.Component {
    constructor(props) {
        super(props);
        const {data, errors} = props;
        this.state = {
            career: (data || {}).career,
            jobs: (data || {}).jobs
        };
    }

    submitForm(e) {
        e.preventDefault();
        alert("Implementation needed!");
    }

    render() {

        return (
            <>
                <SEO
                    title={this.state.career.title}
                    image={this.state.career.image ? this.state.career.image : null}
                />
                <HeroLayout
                    color='blue'
                    {...this.state.career}
                />
                <Container>
                    <div className='-mx-8 md:flex md:justify-center pb-12'>
                        <div className="px-8 md:w-3/5">
                            <div className="body-text">
                            {this.state.career._rawAboveAds &&
                                <PortableText blocks={this.state.career._rawAboveAds}/>}
                            </div>
                        </div>
                    </div>
                </Container>
                <Container>
                    <div className='md:px-130 pb-10'>
                        <div className='flex md:-mx-4 flex-wrap pb-4'>
                        {this.state.jobs.edges.map(({node}, index) => {
                            return (
                                <JobCard key={`post-${index}`} node={node}/>
                            )
                        })}
                        </div>
                    </div>
                </Container>
                <Container>
                    <div className='-mx-8 md:flex md:justify-center pb-12'>
                        <div className="px-8 md:w-3/5">
                            <div className="body-text">
                            {this.state.career._rawBelowAds &&
                                <PortableText blocks={this.state.career._rawBelowAds}/>}
                            </div>
                        </div>
                    </div>
                </Container>
            </>
        );
    }
}

export default KarrierePage;
